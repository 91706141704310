import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CheckCircle, Download } from 'lucide-react';

interface DownloadUrl {
  productId: string;
  url: string;
}

export function Success() {
  const [downloadUrls, setDownloadUrls] = useState<DownloadUrl[]>([]);
  const [error, setError] = useState<string>('');
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get('session_id');

  useEffect(() => {
    if (sessionId) {
      fetch(`/api/checkout-session/${sessionId}`)
        .then(async (res) => {
          if (!res.ok) {
            const errorData = await res.json();
            throw new Error(errorData.error || 'Failed to verify payment');
          }
          return res.json();
        })
        .then(data => {
          if (data.payment_status === 'paid' && data.downloadUrls) {
            setDownloadUrls(data.downloadUrls);
          } else {
            setError('Payment verification failed. Please contact support.');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          setError('Failed to verify payment. Please contact support.');
        });
    }
  }, [sessionId]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-sm p-8 text-center">
        <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-6" />
        <h1 className="text-2xl font-bold text-gray-900 mb-4">
          Thank you for your purchase!
        </h1>
        <p className="text-gray-600 mb-8">
          Your payment has been successfully processed.
        </p>
        
        {error ? (
          <div className="text-red-600 mb-4">{error}</div>
        ) : (
          <div className="space-y-4">
            {downloadUrls.map((download) => (
              <a
                key={download.productId}
                href={download.url}
                className="flex items-center justify-center gap-2 w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700 transition-colors"
              >
                <Download className="w-5 h-5" />
                Download Extension
              </a>
            ))}
          </div>
        )}

        <Link
          to="/"
          className="mt-6 inline-block text-blue-600 hover:text-blue-800 font-medium"
        >
          Return to Home
        </Link>
      </div>
    </div>
  );
}