import React, { useState } from 'react';
import { ShoppingCart, Chrome, Shield, Download, User, X } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import { Cart } from './components/Cart';
import { AuthModal } from './components/AuthModal';
import { useCartStore } from './store/cartStore';
import { useAuthStore } from './store/authStore';
import { Product } from './types';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const product: Product = {
  id: 'chrome-extension-1',
  name: 'Url Finder Chrome Extension',
  description: 'Enhance your browsing experience with our powerful Chrome extension. Streamline your workflow and boost productivity with intuitive features.',
  price: 9.99,
  image: '/images/product-screenshot.png',
  type: 'chrome_extension'
};

function App() {
  const [showCart, setShowCart] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const { addItem, items, total } = useCartStore();
  const { isAuthenticated, user } = useAuthStore();

  const handlePurchase = async () => {
    if (!isAuthenticated) {
      setShowAuth(true);
      return;
    }

    addItem(product);
    setShowCart(true);
  };

  const handleCheckout = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }

      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }

      const { sessionId } = await response.json();
      
      const { error } = await stripe.redirectToCheckout({ sessionId });
      
      if (error) {
        console.error('Stripe error:', error);
        throw error;
      }
    } catch (error) {
      console.error('Checkout error:', error);
      alert('Failed to process checkout. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <nav className="border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center">
              <span className="text-xl font-semibold">Getting Nimble</span>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 relative">
                {isAuthenticated && user && (
                  <>
                    <span className="text-sm text-gray-600">{user.email}</span>
                    <span className="text-green-500">✓</span>
                  </>
                )}
                <button
                  onClick={() => isAuthenticated ? null : setShowAuth(true)}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <User className="h-6 w-6" />
                </button>
              </div>
              <button
                onClick={() => setShowCart(true)}
                className="p-2 hover:bg-gray-100 rounded-full relative"
              >
                <ShoppingCart className="h-6 w-6" />
                {items.length > 0 && (
                  <span className="absolute -top-1 -right-1 bg-blue-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {items.length}
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            Digital Products That Make Life Better
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            High-quality digital tools and extensions to enhance your daily workflow.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="bg-white rounded-2xl shadow-sm border p-8">
          <div className="flex flex-col md:flex-row gap-8">
            <div className="md:w-1/2">
              <img
                src={product.image}
                alt={product.name}
                className="rounded-lg w-full object-cover"
              />
            </div>
            <div className="md:w-1/2">
              <div className="flex items-center gap-2 text-blue-600">
                <Chrome className="h-5 w-5" />
                <span className="text-sm font-medium">Chrome Extension</span>
              </div>
              <h2 className="mt-4 text-2xl font-bold text-gray-900">{product.name}</h2>
              <p className="mt-4 text-gray-600">{product.description}</p>
              
              <div className="mt-8 space-y-4">
                <div className="flex items-center gap-3">
                  <Shield className="h-5 w-5 text-green-600" />
                  <span>Secure Payment</span>
                </div>
                <div className="flex items-center gap-3">
                  <Download className="h-5 w-5 text-green-600" />
                  <span>Instant Download</span>
                </div>
              </div>

              <div className="mt-8">
                <div className="text-3xl font-bold text-gray-900">${product.price}</div>
                <button
                  onClick={handlePurchase}
                  className="mt-4 w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700 transition-colors"
                >
                  Purchase Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCart && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="absolute right-0 top-0 h-full w-full max-w-md bg-white shadow-xl">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-lg font-semibold">Your Cart</h2>
              <button
                onClick={() => setShowCart(false)}
                className="p-2 hover:bg-gray-100 rounded"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <Cart />
            {items.length > 0 && (
              <div className="p-4 border-t">
                <button
                  onClick={handleCheckout}
                  className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700 transition-colors"
                >
                  Checkout (${total.toFixed(2)})
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {showAuth && <AuthModal onClose={() => setShowAuth(false)} />}

      <footer className="bg-gray-50 border-t">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center text-gray-500 text-sm">
            © {new Date().getFullYear()} Getting Nimble. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;