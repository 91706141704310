import React from 'react';
import { X } from 'lucide-react';
import { useCartStore } from '../store/cartStore';

export function Cart() {
  const { items, removeItem, total } = useCartStore();

  if (items.length === 0) {
    return (
      <div className="p-4 text-center text-gray-500">
        Your cart is empty
      </div>
    );
  }

  return (
    <div className="p-4">
      {items.map((item) => (
        <div key={item.product.id} className="flex items-center justify-between py-2 border-b">
          <div className="flex items-center gap-4">
            <img
              src={item.product.image}
              alt={item.product.name}
              className="w-16 h-16 object-cover rounded"
            />
            <div>
              <h3 className="font-medium">{item.product.name}</h3>
              <p className="text-sm text-gray-500">Quantity: {item.quantity}</p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <span className="font-medium">${item.product.price}</span>
            <button
              onClick={() => removeItem(item.product.id)}
              className="p-1 hover:bg-gray-100 rounded"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>
      ))}
      <div className="mt-4 flex justify-between items-center">
        <span className="font-medium">Total:</span>
        <span className="text-xl font-bold">${total.toFixed(2)}</span>
      </div>
    </div>
  );
}